import './polyfills';
import { load } from 'recaptcha-v3';

// Components
import Accordians from './components/accordians';
import Accordion from './components/accordion';
import Announcement from './components/announcement';
import BlindsColorSections from './components/blinds-color-sections';
import BlindsColorWheel from './components/blinds-color-wheel';
import CalendlyScheduler from './components/calendly-scheduler';
import CarouselSlider from './components/carousel-slider';
import EntryHero from './components/entry-hero';
import EntryNav from './components/entry-nav';
import Filters from './components/filters';
import Form from './components/form';
import Header from './components/header';
import HeaderLp from './components/header-lp';
import HeroSlider from './components/hero-slider';
import ImageComparison from './components/image-comparison';
import Input from './components/input';
import InspirationGallery from './components/inspiration-gallery';
import ListSlider from './components/list-slider';
import LocatorResults from './components/locator-results';
import Main from './components/main';
import Modal from './components/modal';
import PageHero from './components/page-hero';
import ProductCostsRange from './components/product-costs-range';
import ProductFeatures from './components/product-features';
import PaidSearchProductFeatures from './components/paid-search-product-features';
import DesignAdvisor from './components/design-advisor';
import ProductFilters from './components/product-filters';
import ProductRegistrationForm from './components/product-registration-form';
import ProductsCarousel from './components/products-carousel';
import ProjectImages from './components/project-images';
import Projects from './components/projects';
import ProjectsMap from './components/projects-map';
import ResponsiveImage from './components/responsive-image';
import Sidebar from './components/sidebar';
import TestimonialsCarousel from './components/testimonials-carousel';
import Video from './components/video';

// Utilities
import { instantiate } from './utilities/components';
import LazyLoadImages from "./components/lazy-load-images";

const RECAPTCHA_SITE_KEY = '6LeYj_ElAAAAAAFeI666dgCL1viQad5KR6waIUlW';

/* eslint-disable quote-props */
const classMap = {
    'accordians': Accordians,
    'accordion': Accordion,
    'announcement': Announcement,
    'blinds-color-sections': BlindsColorSections,
    'blinds-color-wheel': BlindsColorWheel,
    'calendly-scheduler': CalendlyScheduler,
    'carousel-slider': CarouselSlider,
    'entry-hero': EntryHero,
    'entry-nav': EntryNav,
    'filters': Filters,
    'form': Form,
    'header': Header,
    'header-lp': HeaderLp,
    'hero-slider': HeroSlider,
    'image-comparison': ImageComparison,
    'input': Input,
    'inspiration-gallery': InspirationGallery,
    'list-slider': ListSlider,
    'design-slider': ListSlider,
    'locator-results': LocatorResults,
    'main': Main,
    'modal': Modal,
    'page-hero': PageHero,
    'product-costs-range': ProductCostsRange,
    'product-features': ProductFeatures,
    'paid-search-product-features': PaidSearchProductFeatures,
    'product-filters': ProductFilters,
    'product-registration-form': ProductRegistrationForm,
    'products-carousel': ProductsCarousel,
    'project-images': ProjectImages,
    'projects': Projects,
    'projects-map': ProjectsMap,
    'responsive-image': ResponsiveImage,
    'sidebar': Sidebar,
    'testimonials-carousel': TestimonialsCarousel,
    'video': Video,
    'lazy-load-images': LazyLoadImages,
};
/* eslint-enable quote-props */

// Event handler functions
function handleDOMConentLoaded() {

    // Load reCAPTCHA at startup
    if (!window.recaptchaLoading) {
        window.recaptchaLoading = true;

        // Load reCAPTCHA once for the entire page
        load(RECAPTCHA_SITE_KEY)
            .then(r => {
                window.recaptcha = r;
                console.log('reCAPTCHA loaded globally once');

                // Dispatch an event to notify all forms that reCAPTCHA is ready
                document.dispatchEvent(new Event('recaptchaLoaded'));
            })
            .catch(error => {
                console.error('Error loading reCAPTCHA:', error);
                window.recaptchaLoading = false;
            });
    }

    // Call component constructors
    instantiate(classMap);

    new LazyLoadImages();
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const form = el.querySelector('form');

        if (!form) {
            return;
        }

        // Skip forms with custom handlers entirely
        if (form.id === 'newsletterForm' || form.id === 'downloadForm' ||
            form.id === 'sweepsForm' || form.id === 'advisorsRequestsForm') {
            console.log('Form.js skipping form with custom handler: ' + form.id);
            return;
        }

        const submit = form.querySelector('[type="submit"]');

        // Function to set up form submission once reCAPTCHA is available
        const setupForm = () => {
            form.addEventListener('submit', function (e) {
                if (!window.recaptcha) {
                    console.error('reCAPTCHA not available');
                    return;
                }

                e.preventDefault();

                submit.disabled = true;
                submit.value = 'Processing...';

                // Get reCAPTCHA score token
                window.recaptcha.execute('form').then(token => {
                    // Create hidden input in form to pass reCAPTCHA score token to server
                    const input = document.createElement('input');
                    input.setAttribute('type', 'hidden');
                    input.setAttribute('name', 'token');
                    input.setAttribute('value', token);

                    form.appendChild(input);
                    form.submit();
                });
            });

            console.log('Standard form handler attached to: ' + form.id);
        };

        // If reCAPTCHA is already loaded, set up the form immediately
        if (window.recaptcha) {
            setupForm();
        } else {
            // Otherwise, wait for the recaptchaLoaded event
            document.addEventListener('recaptchaLoaded', setupForm);
        }
    }
}

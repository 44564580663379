const selfOrClosest = (element, selector) => {
    return element.matches(selector) && element || element.closest(selector)
}

class Announcement {

    constructor(element) {
        this.element = element

        this.scrollFunction = this.scrollFunction.bind(this)
        this.prevScrollpos = window.pageYOffset;
    }

    scrollFunction() {

        if(window.scrollY==0){
            this.element.classList.add('is-active');
        } else {
            this.element.classList.remove('is-active');
        }
    }

    init() {
        if(window.scrollY==0){
            this.element.classList.add('is-active');
        }
        window.addEventListener('scroll', this.scrollFunction)
    }

}



export default class {
    constructor({

    }) {
        const announcementElems = Array.from(document.querySelectorAll('.announcement'))
        const announcements = announcementElems.map((announcementElem) => {
            const announcement = new Announcement(announcementElem)
            announcement.init()
            return announcement
        })

        console.log(announcements)
    }
}
